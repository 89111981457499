@import "../../styles/styles.scss";

.sidebar-navigation-container {
  /* width: 90px; */
  width: 100%;
  height: 70px;
  /* padding: 10px 20px 10px 20px; */
  border-radius: 8px;
  gap: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.sidebar-navigation-text {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 0px;
}

.selected-tab {
  display: flex;
  width: 90px;
  height: 70px;
  padding: 8px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // gap: 6px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--secondary-1, #0073e6);
  background: #e5f1fc;
}
