@import "../../styles/styles.scss";

.card {
  // width: 388px;
  width: 30%;
  height: 209px;
  padding: 16px;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  border: 0.5px #dadce0 solid;
  display: flex;
  flex-direction: column;
  @include m_desktop{
    width: 100%;
    height: 180px;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;

    .avatar {
      width: 50px;
      height: 50px;
      background-color: #ebe8fd;
      border-radius: 12px;
      @include flex();
    }

    .header-content {
      flex: 1;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      // align-items: flex-start;
      gap: 10px;
      margin-top: 10px;

      .title {
        color: #1c4980;
        font-size: 18px;
        font-family: Inter;
        font-weight: 500;
        line-height: 25.2px;
        word-wrap: break-word;
      }

      .meta {
        display: flex;
        gap: 10px;
        margin-top: 5.5px;

        .meta-divider {
          width: 1px;
          height: 16px;
          background-color: #dadce0;
        }

        .meta-item {
          display: flex;
          align-items: center;
          // gap: 4px;

          .meta-text {
            color: #8da4bf;
            font-size: 12px;
            font-family: Inter;
            font-weight: 500;
            word-wrap: break-word;
          }
        }
      }
    }

    .action-icon {
      width: 30px;
      height: 30px;
      padding: 5px;
      background: white;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  .divider {
    height: 0.5px;
    border: 0.5px #dadce0 dotted;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .footer-item {
      .footer-value {
        color: #1c4980;
        font-size: 14px;
        font-family: Inter;
        font-weight: 600;
        line-height: 19.6px;
        word-wrap: break-word;
        display: flex;
      }

      .footer-label {
        color: #1c4980;
        font-size: 12px;
        font-family: Inter;
        font-weight: 500;
        line-height: 16.8px;
        word-wrap: break-word;
      }
    }

    .footer-actions {
      display: flex;
      align-items: center;
      gap: 10px;

      .avatar-actions {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .action-profile {
        display: flex;
        width: 30px;
        height: 30px;
        padding: 5px 6px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        gap: 10px;
        border-radius: 32.728px;
        border: 1px solid #fff;
        background: #6548ee;
      }

      .action-button {
        display: flex;
        height: 30px;
        padding: 2px 8px 2px 6px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 50px;
        border: 1px solid var(--text-100, #1c4980);
        background: var(--text-white, #fff);

        .action-text {
          color: #1c4980;
          font-size: 14px;
          font-family: Inter;
          font-weight: 500;
          line-height: 19.6px;
          word-wrap: break-word;
        }
      }
    }
  }
}
