@import "../../styles/styles.scss";

.header-mobile-container {
  display: none;
  @include m_desktop {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 0px 15px;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .header-mobile-img {
      display: flex;
      width: 40px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 60px;
      background: #f2f8fe;
    }
    .header-mobile-heading {
      color: #1c4980;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      margin-left: 10px;
    }
    .header-mobile-section {
      @include flex();
    }
  }
}

.mobile-my-asessment {
  display: none;
  @include m_desktop {
    display: flex;
    height: 40px;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .asessment-mobile {
      display: flex;
      height: 40px;
      padding: 10px 0px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-bottom: 2px solid #f6f8fa;
      background: #fff;
    }
    .mobile-asessment-text {
      color: #3e6493;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0px;
    }
  }
}

.container-topbar {
  width: 100%;
  height: 70px;
  //   @include flex-center(space-between, center);
  @include flex(row, center, space-between);
  border-bottom: 1px solid $border-color;
  @include m_desktop {
    display: none;
  }

  .left-section {
    width: 276px;
    align-self: stretch;
    gap: 20px;
    // @include flex-center(flex-start, center, 20px);
    @include flex(row, center, flex-start);
    @include m_desktop {
      width: 100%;
    }

    .tab-one {
      color: $primary-color;
      font-size: 20px;
      font-family: Inter;
      font-weight: 600;
      line-height: 28px;
      word-wrap: break-word;
    }

    .divider {
      border: 0.5px $border-color solid;
      width: 0;
      height: 46px;
    }

    .actions {
      //   @include flex-center(flex-start, flex-start, 20px);
      @include flex(row);
      cursor: pointer;
      gap: 20px;
      border-bottom: 3px solid #0073e6;
      height: 100%;

      .tab-two {
        width: 116px;
        padding-top: 10px;
        padding-bottom: 5px;
        background: white;
        // @include flex-center(center, center, 10px);
        @include flex();
        gap: 10px;

        .tab-two-text {
          color: $secondary-color;
          font-size: 14px;
          font-family: Inter;
          font-weight: 600;
          word-wrap: break-word;
        }
      }
    }
  }

  .avatar {
    width: 40px;
    height: 40px;
    padding: 10px;
    background: white;
    border-radius: 70px;
    margin-right: 24px;
    @include flex();
    @include m_desktop {
      display: none;
    }

    .icon-container {
      width: 24px;
      height: 24px;
      position: relative;
    }
  }
}
