body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-flex{
  display: flex;
}

.d-flex-jc-al{
  display: flex;
  align-items: center;
  justify-content: center;
}


::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #349ef5;
  border: 0px none #ffffff;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: #005aa3;
}
::-webkit-scrollbar-thumb:active {
  background: #004f8f;
}
::-webkit-scrollbar-track {
  background: #c7c7c7;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #bfbfbf;
}
::-webkit-scrollbar-track:active {
  background: #bfbfbf;
}
::-webkit-scrollbar-corner {
  background: transparent;
}