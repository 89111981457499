@import "../../styles/styles.scss";

.sidebar-container {
  width: 100%;
  padding: 16px 0px 16px 0px;
  @include s_desktop{
    display: none;
  }
}

.horizontal-dotted-line {
  width: 90px;
  border: 1px dotted #bacbd5;
}

.role-name {
  width: 47px;
  height: 18px;
  padding: 6px 8px 6px 8px;
  border-radius: 22px;
  gap: 6px;
  background: linear-gradient(0deg, #d63500, #d63500),
    linear-gradient(0deg, #fbebea, #fbebea);
  border: 1px solid #d63500;
}

.badge {
  width: 47px;
  height: 18px;
  padding: 2px 6px;
  background: #fbebea;
  border-radius: 22px;
  border: 0.5px #d63500 solid;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: inline-flex;

  .badge-text {
    color: #d63500;
    font-size: 10px;
    font-family: Inter;
    font-weight: 500;
    word-wrap: break-word;
  }
}

.section-admin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
}

.section-round-status {
  margin-top: 16px;
}

.role-name-text {
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  background: #d63500;
}

.round-status {
  color: #1c4980;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 0px;
}

.sidebar-section-one {
  @include flex(column, center, center);
  margin-top: 16px;
}
