@import "../../styles/styles.scss";

.modal-content-container {
  width: 85%;
  @include m_desktop{
    width: 100%;
  }
}

.header-modal {
  @include flex(row, center, space-between);
  display: flex;
  height: 70px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.modal-title {
  color: #1c4980;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.input-box {
  @include flex(column, flex-start, center);
}

.input {
  display: flex;
  height: 10px;
  padding: 15px;
  //   width: 80%;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #dadce0;
  background: #fff;
  margin-top: 10px;
}

.label-name {
  color: #1c4980;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
}

.select-input {
  display: flex;
  height: 50px;
  //   width: 85%;
  padding: 10px 16px 10px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #dadce0;
  background: #fff;
  margin-top: 10px;
}

.submit-action {
  display: flex;
  height: 20px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  background: #0073e6;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.submit-action-container {
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  margin-top: 20px;
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #dde5ea;
  overflow: auto;
  margin-top: 10px;
}

.chip {
  display: flex;
  padding: 1px 8px 1px 8px;
  justify-content: center;
  align-items: center;
//   gap: 2px;
  border-radius: 22px;
  background: #ddedff;
}

.chip-text {
  color: #1c4980;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.chip-close{
    display: flex;
    justify-content: center;
    align-items: center;
}
